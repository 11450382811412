import { createIcon } from '@chakra-ui/icons';

const Home = createIcon({
  displayName: 'Home',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0142 3.0001C12.3026 3.00418 12.5752 3.13259 12.762 3.35235L21.262 13.3524C21.5144 13.6493 21.5713 14.0659 21.4077 14.4197C21.2441 14.7735 20.8898 15 20.5 15H19V21C19 21.5523 18.5523 22 18 22H13V17H11V22H6.00002C5.44773 22 5.00002 21.5523 5.00002 21V15H3.00002C2.60511 15 2.24718 14.7676 2.08652 14.4068C1.92585 14.0461 1.99254 13.6246 2.25672 13.331L11.2567 3.33104C11.4497 3.11665 11.7258 2.99602 12.0142 3.0001ZM5.24538 13H7.00002V20H9.00002V15H15V20H17V13H18.3376L11.9785 5.51874L5.24538 13Z"
      fill="currentcolor"
    />
  )
});

export default Home;
