import { Image, Square } from '@chakra-ui/react';

const TeamImage = ({ team, size = 20 }) => {
  const { flag, logo, id } = team;
  return flag ? (
    <Image h={`${size}px`} objectFit="contain" src={flag} alt={`Team Flag ${id}`} />
  ) : logo ? (
    <Square bg="white" boxSize={`${size + 4}px`} border="1px" borderColor="black.8" rounded="sm">
      <Image h={`${size}px`} objectFit="contain" src={logo} alt={`Team Flag ${id}`} />
    </Square>
  ) : null;
};

export default TeamImage;
