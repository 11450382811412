const getPlayerData = (teamsData, player_id, team_id) => {
  if (!teamsData) {
    return {};
  }
  const team = teamsData.find((el) => el.team_id === team_id);
  if (!team.player) {
    return {};
  }
  return team.player.find((el) => el.player_id === player_id) || {};
};

export default getPlayerData;
