import { useEffect } from 'react';
import { Box, Center, Flex } from '@chakra-ui/react';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import VideoList from '../components/VideoList';

import NavHeader from '../NavHeader/NavHeader';
import 'video-react/dist/video-react.css';
import LayoutContainer from '_shared/components/LayoutComponents/LayoutContainer';
import HeaderText from '../NavHeader/HeaderText';

const VideoDisplay = ({
  onCloseVideo,
  groupData,
  videoSelected,
  setVideoSelected,
  primaryLabel,
  secondaryLabel
}) => {
  const handleItemClick = (item) => {
    setVideoSelected(item);
  };
  const handleOnVideoEnd = () => {
    if (videoSelected !== groupData.length - 1) {
      setVideoSelected(videoSelected + 1);
    }
  };

  // scroll to top to ensure video is in view on load
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  const videoItem = groupData[videoSelected];

  return (
    <LayoutContainer
      header={
        <Box bg="primary.shade.70">
          <NavHeader handleBack={onCloseVideo} buttonVariant="ghostDark">
            <HeaderText primaryText={primaryLabel} secondaryText={secondaryLabel} />
          </NavHeader>
          {videoItem ? (
            <>
              <Player
                src={videoItem.video_url}
                className="video-player-panel"
                playsInline={true}
                autoPlay={true}
                // muted={true}
                onEnded={handleOnVideoEnd}
              >
                <BigPlayButton position="center" />
                <ControlBar autoHide={true} className="control-bar-panel" autoHideTime={1000} />
              </Player>
              <Flex color="white.100" direction="column" py="2" px="3" gap="1">
                <Flex justify="space-between" alignItems="center">
                  <Box fontSize="xs" fontWeight="700">
                    {videoItem.overs_unique}
                  </Box>
                </Flex>
                <Box fontSize="sm">{videoItem.commentary}</Box>
              </Flex>
            </>
          ) : (
            <Center w="100%" h="210px">
              Video not available
            </Center>
          )}
        </Box>
      }
      content={
        <Box pt="2" pb="4" px="4">
          <VideoList
            data={groupData}
            handleItemClick={handleItemClick}
            selectedVideoIndex={videoSelected}
          />
        </Box>
      }
    />
  );
};

export default VideoDisplay;
