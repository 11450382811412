import { extendTheme } from '@chakra-ui/react';

import colors from './colors';
import { fontSizes } from './typography';
import shadows from './shadows';
import buttonStyles from './componentStyles/button';
import inputStyles from './componentStyles/input';
import selectStyles from './componentStyles/select';
import avatarStyles from './componentStyles/avatar';

// const breakpoints = {
//   sm: '0px',
//   md: '650px',
//   lg: '960px',
//   xl: '1200px',
//   '2xl': '1536px'
// };

// we override the default Chakra theme with our custom themes here
const overrides = {
  colors,
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`
  },
  fontSizes,
  shadows,
  components: {
    Button: buttonStyles,
    Popover: {
      baseStyle: {
        popper: {
          width: 'fit-content',
          maxWidth: 'fit-content'
        }
      }
    },
    Table: {
      defaultProps: {
        colorScheme: 'table'
      }
    },
    Input: inputStyles,
    Select: selectStyles,
    FormLabel: {
      baseStyle: {
        mb: '0',
        fontWeight: '700'
      }
    },
    Avatar: avatarStyles
  }
};

const theme = extendTheme(overrides);
// We also inherit all of Chakra's default theme values
// https://chakra-ui.com/docs/theming/theme

export default theme;
