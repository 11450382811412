import './fullScreenError.css';

export default function FullScreenError() {
  return (
    <div className="container">
      <div className="content">
        <div className="logo-container">
          <div className="logo">
            <img
              alt="logo"
              src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png"
            />
          </div>
        </div>
        <h2>We are sorry</h2>
        <h1>An error has occured</h1>
        <p>Please try refreshing the page.</p>
        <a href="/">
          <button className="home-button">Home</button>
        </a>
      </div>
    </div>
  );
}
