import { Box, Flex, Select } from '@chakra-ui/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import getHomeAwayData from '../utils/getHomeAwayData';
import ButtonSelector from '_shared/components/ButtonSelector';
import TeamImage from '_shared/components/TeamImage';
import NavHeader from '../NavHeader/NavHeader';

const VideoScorecardHeader = ({ matchData, inningsData }) => {
  const { comp_id } = matchData;

  const search = useSearch();
  const navigate = useNavigate();
  const setTypeSelected = (value) => {
    navigate({ search: (old) => ({ ...old, type: value }) });
  };
  const setInningsSelected = (value) => {
    navigate({ search: (old) => ({ ...old, innings: value }) });
  };
  const handleClick = () => {
    navigate({ to: `/comp/${comp_id}`, search: {} });
  };
  const { homeTeam, awayTeam } = getHomeAwayData(matchData);
  return (
    <Flex direction="column" borderBottom="1px" borderColor="black.8" bg="white.100">
      <NavHeader handleBack={handleClick} buttonVariant="ghost">
        <Flex alignItems="center" fontSize="lg" gap="2" fontWeight="600" justifyContent="center">
          <TeamImage team={homeTeam} />
          <Box textAlign="center">{homeTeam.name}</Box>
          <Box>v</Box>
          <TeamImage team={awayTeam} />
          <Box textAlign="center">{awayTeam.name}</Box>
        </Flex>
      </NavHeader>
      <Flex
        p="10px"
        pt="0px"
        gap="3"
        w="100%"
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
      >
        <ButtonSelector
          handleChange={setTypeSelected}
          value={search?.type}
          options={['batting', 'bowling']}
          buttonMinWidth="140px"
        />
        <Select
          value={search.innings}
          onChange={(e) => setInningsSelected(e.target.value)}
          maxW="300px"
          w="100%"
          bg="white"
          size="md"
          fontSize="14px"
        >
          {inningsData.map((el) => {
            const { innings_number } = el;
            return (
              <option key={innings_number} value={innings_number}>
                {el.label}
              </option>
            );
          })}
        </Select>
      </Flex>
    </Flex>
  );
};

export default VideoScorecardHeader;
