const getBBBGroups = (typeSelected) => {
  const BBBGroups = [
    { value: 'sixes', label: 'Sixes' },
    { value: 'fours', label: 'Fours' },
    { value: 'twosThrees', label: 'Twos & Threes' },
    { value: 'singles', label: 'Singles' },
    { value: 'dots', label: 'Dots' }
  ];
  if (typeSelected === 'batting') {
    BBBGroups.unshift({ value: 'wickets', label: 'Dismissal' });
  }
  if (typeSelected === 'bowling') {
    BBBGroups.unshift({ value: 'wickets', label: 'Wickets' });
  }
  return BBBGroups;
};

export default getBBBGroups;
