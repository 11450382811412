import {
  Accordion,
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Flex,
  Circle
} from '@chakra-ui/react';
import VideoList from '../components/VideoList';
import getColor from './utils/getColor';

const BBBAccordianItem = ({ value, label, data, handleItemClick }) => {
  return (
    <AccordionItem border="0px">
      <AccordionButton borderBottom="1px" borderColor="black.8" h="76px">
        <Flex justify="space-between" flex="1" alignItems="center" pr="3" gap="2">
          <Circle
            size="12px"
            bg={getColor(value, 'bg')}
            color={getColor(value, 'color')}
            border="1px"
            mt="1px"
            borderColor={getColor(value, 'border')}
          />
          <Box fontSize="sm" fontWeight="600" flexGrow="1" textAlign="left">
            {label}
          </Box>
          <Box fontSize="xs" color="black.60">
            {data.length || ''}
          </Box>
        </Flex>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel p="0" pb="2" borderBottom="1px" borderColor="black.8">
        <VideoList data={data} handleItemClick={handleItemClick} />
      </AccordionPanel>
    </AccordionItem>
  );
};

const PlayerViewAccordian = ({
  data,
  BBBGroups,
  setGroupVideoSelected,
  accordianValue,
  onAccordianChange
}) => {
  return (
    <Accordion allowToggle index={accordianValue} onChange={onAccordianChange}>
      {BBBGroups.filter(({ value }) => {
        return data[value]?.length !== 0;
      }).map(({ value, label }) => {
        return (
          <BBBAccordianItem
            handleItemClick={(item) => {
              setGroupVideoSelected(value, item);
            }}
            label={label}
            value={value}
            data={data[value]}
          />
        );
      })}
    </Accordion>
  );
};

export default PlayerViewAccordian;
