import { Box, Flex, Image, Square } from '@chakra-ui/react';

const InningsScore = ({ data }) => {
  const { runs, overs, wickets, closure_name, xR, xW } = data;
  return (
    <Flex direction="column">
      <Flex alignItems="center" gap="2">
        <Box fontWeight="700" fontSize="16px" color="black.76">
          {runs}
          {closure_name !== 'all out' && wickets !== 10 ? `/${wickets}` : null}
          {closure_name === 'declared' ? 'd' : null}
        </Box>
        <Box fontSize="14px" color="black.60">{`(${overs} ov)`}</Box>
      </Flex>
      {xR && xW ? (
        <Box fontSize="14px" color="black.60">
          x{xR}/{xW}
        </Box>
      ) : null}
    </Flex>
  );
};

const TeamScore = ({ inningsData }) => {
  const inningsScore = () => {
    if (!inningsData.innings || inningsData.innings.length === 0) {
      return 'Yet to bat';
    }
    if (inningsData.innings.length === 1) {
      // return <InningsScore data={inningsData.innings[0]} />;
      return (
        <>
          <InningsScore data={inningsData.innings[0]} />
        </>
      );
    }
    return inningsData.innings.map((inn, idx) => {
      return <InningsScore key={idx} data={inn} />;
    });
  };

  return (
    <Flex gap="3" flex="1">
      <Square size="70px" border="1px" borderColor="black.8" rounded="md">
        <Image
          boxSize="46"
          objectFit="contain"
          m="1"
          src={
            inningsData.emblem
              ? inningsData.emblem
              : `https://cv-general-images.s3.eu-central-1.amazonaws.com/3D/cricviz_icon.png`
          }
          alt={`Team Emb ${inningsData.abbrev}`}
        />
      </Square>
      <Flex direction="column" minWidth="200px" mt={'auto'} mb={'auto'}>
        <Box fontWeight="700" fontSize="16px" color="black.76">
          {inningsData.name}
        </Box>
        <Flex gap="4">{inningsScore()}</Flex>
      </Flex>
    </Flex>
  );
};

export default TeamScore;
