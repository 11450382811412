import { Center, Image, Box, Heading, Text } from '@chakra-ui/react';
import RouterButton from '_shared/components/Utils/RouterButton';

export default function HandledError({ headerString, messageString }) {

  const defaultHeaderString = "We're sorry, an error has occurred";
  const defaultMessageString = 'Please try refreshing the page or return home';

  return (
    <Center>
      <Box>
        <Center>
          <Image
            src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png"
            mt="30vh"
            mb="10"
            h={`50px`}
            objectFit="contain"
          />
        </Center>
        <Heading
          fontSize={{ base: 'lg', lg: '2xl' }}
          as="h2"
          size="xl"
          mb="5"
          align="center"
        >
          {headerString || defaultHeaderString}
        </Heading>
        <Text fontSize={{ base: 'xs', lg: 'sm' }} mb="10" align="center">
          {messageString || defaultMessageString}
        </Text>
        <Center>
          <RouterButton variant="solid" to="/home">
            Home
          </RouterButton>
        </Center>
      </Box>
    </Center>
  );
}
