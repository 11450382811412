import { Box, Flex } from '@chakra-ui/react';

const HeaderText = ({ primaryText, secondaryText }) => {
  return (
    <Flex direction="column" flexGrow="1" color="white" gap="1" alignItems="center">
      {secondaryText ? (
        <Box fontSize="xs" fontWeight="600">
          {secondaryText}
        </Box>
      ) : null}
      <Box fontSize="md" fontWeight="700">
        {primaryText}
      </Box>
    </Flex>
  );
};

export default HeaderText;
