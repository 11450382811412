import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { useEffect } from 'react';
import { getVideoScorecard } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import HandledError from '_shared/errors/HandledError';
import Loading from './components/Loading';
import InningsGroup from './InningsGroup/InningsGroup';
import PlayerView from './PlayerView/PlayerView';
import ScorecardView from './ScorecardView/ScorecardView';
import getInningsData from './utils/getInningsData';
import getMatchName from './utils/getMatchName';
import usePageTitle from '_shared/hooks/usePageTitle';

const VideoScorecardPage = () => {
  const {
    params: { objectId: matchId }
  } = useMatch();
  const { isLoading, error, data } = useQueryFetch(
    ['videoScorecard', matchId],
    () => getVideoScorecard(matchId),
    {
      staleTime: 30 * 1000
    }
  );
  usePageTitle(data?.match?.match_short_name);

  const navigate = useNavigate();
  const search = useSearch();
  useEffect(() => {
    if (!search.type || search.type !== 'bowling') {
      navigate({
        search: (old) => ({ ...old, type: 'batting' }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [search.type, navigate]);

  useEffect(() => {
    if (
      data?.innings &&
      !data.innings.some((el) => {
        return el.innings_number === search.innings?.toString();
      })
    ) {
      const inningsNumber = data.innings[0]?.innings_number || '1';
      navigate({
        search: (old) => ({ ...old, innings: inningsNumber }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [data, navigate, search.innings]);
  if (error || data?.error) return <HandledError/>;
  if (isLoading || !search.type || !search.innings) return <Loading h="100vh" />;

  const inningsData = getInningsData(data, search.innings);

  return (
    <>
      {!search.playerId && !search.group ? <ScorecardView data={data} /> : null}
      {inningsData && search.playerId ? (
        <PlayerView
          matchName={getMatchName(data.match)}
          inningsData={inningsData}
          teamsData={data.team}
        />
      ) : null}
      {inningsData && !search.playerId && search.group ? (
        <InningsGroup inningsData={inningsData} />
      ) : null}
    </>
  );
};

export default VideoScorecardPage;
