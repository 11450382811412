import bowlerTypes from '_shared/definitions/bowlerTypes';

export const checkBowlerHandedness = (bowlerType) => {
  if (bowlerTypes.right.fast.includes(bowlerType) || bowlerTypes.right.spin.includes(bowlerType)) {
    return 'right';
  }
  if (bowlerTypes.left.fast.includes(bowlerType) || bowlerTypes.left.spin.includes(bowlerType)) {
    return 'left';
  }
};

export const getUserInitials = (user_info) => {
  let initials = '';
  if (user_info.full_name) {
    const full_name = user_info.full_name.trim();
    const nameArray = full_name.split(' ');
    if (nameArray.length > 0) {
      initials += nameArray[0][0].toUpperCase();
    }
    if (nameArray.length > 1) {
      initials += nameArray[nameArray.length - 1][0].toUpperCase();
    }
  }
  return initials;
};

export const createUUID = (
  charSet = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
) => {
  let uuid = '';
  while (uuid.length < 13) {
    const rand = Math.floor(Math.random() * charSet.length);
    uuid += charSet[rand];
  }
  return uuid;
};

export const handlePermissions = (permId, permList) => {
  const permsAvailable = [];
  permList.forEach((el) => {
    permsAvailable.push(el.permission_id);
  });
  if (permsAvailable.includes(1) || permsAvailable.includes(permId)) {
    return true;
  }
  return false;
};
