import React from 'react';
import PropTypes from 'prop-types';
import { log } from '_shared/errors/log';
import { errorTypes } from '_shared/errors/errorTypes';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    log({
      level: 'ERROR',
      message: `${
        errorTypes.REACT_ERROR_BOUNDARY
      } has occured | ${error} | ${errorInfo.componentStack.slice(0, 120)}`
    });
  }

  render() {
    if (this.state.hasError) {
      return this.props.renderError();
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  renderError: PropTypes.func.isRequired,
  children: PropTypes.node
};
