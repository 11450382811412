import { createIcon } from '@chakra-ui/icons';

const VideoPlay = createIcon({
  displayName: 'VideoPlay',
  viewBox: '0 0 24 24',
  path: (
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM10.7704 15.6048L15.6457 12.4706C15.9519 12.2738 15.9519 11.8262 15.6458 11.6294L10.7704 8.49524C10.4376 8.28133 10 8.52025 10 8.91583V15.1842C10 15.5798 10.4376 15.8187 10.7704 15.6048Z"
      fill="currentcolor"
    />
  )
});

export default VideoPlay;
