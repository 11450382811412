const getHeaderData = (inningsData, teamsData, playerSelected, typeSelected) => {
  if (!inningsData || !inningsData[typeSelected]) {
    return {};
  }
  const playerInningsData =
    inningsData[typeSelected].find((el) => el.player_id === playerSelected) || {};
  const team = teamsData.find((el) => el.team_id === inningsData[`${typeSelected}_team_id`]) || {};
  if (!team.player) {
    return {};
  }
  const playerInfo = team.player.find((el) => el.player_id === playerSelected) || {};
  return { ...playerInningsData, ...playerInfo };
};

export default getHeaderData;
