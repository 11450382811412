const { Box, Center, Spinner } = require('@chakra-ui/react');

const Loading = ({ ...boxProps }) => {
  return (
    <Box {...boxProps}>
      <Center h="100%">
        <Spinner />
      </Center>
    </Box>
  );
};

export default Loading;
