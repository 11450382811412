import { CircleAlert, CircleCheckmark, CircleX, CirclInfo, LiveIndicator } from '_icons/svgs';

const headerTypes = {
  live: {
    bgColor: 'positive.tint.90',
    color: 'positive.shade.30',
    icon: LiveIndicator
  },
  won: {
    bgColor: 'white.100',
    color: 'positive.shade.10',
    icon: CircleCheckmark,
    border: true
  },
  lost: {
    bgColor: 'negative.tint.90',
    color: 'negative.shade.10',
    icon: CircleX
  },
  noResult: {
    bgColor: 'white.100',
    color: 'black.76',
    icon: CirclInfo,
    border: true
  },
  warning: {
    bgColor: 'warning.tint.90',
    color: 'warning.shade.10',
    icon: CircleAlert
  }
};

const getHeaderType = (matchData) => {
  const { match_status, result_str } = matchData;
  if (match_status === 'live') {
    return headerTypes.live;
  }
  if (result_str && ['delayed', 'abandoned'].some((el) => result_str.toLowerCase().includes(el))) {
    return headerTypes.warning;
  }
  if (match_status === 'complete' && result_str && result_str.toLowerCase().includes('lost')) {
    return headerTypes.lost;
  }
  if (
    match_status === 'complete' &&
    result_str &&
    ['win', 'won'].some((el) => result_str.toLowerCase().includes(el))
  ) {
    return headerTypes.won;
  }
  return headerTypes.noResult;
};

export default getHeaderType;
