import { fetchClient } from './clientSetup';

const qtpApi = process.env.REACT_APP_API_URL || '';

export const getVideoScorecard = async (objectId) => {
  const url = `${qtpApi}/cricket/match/${objectId}/video_scorecard`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getCompetitionMatches = async (objectId) => {
  let url = `${qtpApi}/cricket/video_scorecard_matches/`;
  if (objectId) {
    url += objectId;
  }

  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const getScorecardVideos = async (matchId, params) => {
  const url = `${qtpApi}/cricket/video_innings/${matchId}`;
  const res = await fetchClient.get(url, { params });
  const { data } = res;
  return data;
};

export const getUserInfo = async () => {
  const url = `${qtpApi}/user_details`;
  const res = await fetchClient.get(url);
  const { data } = res;
  return data;
};

export const postFavourites = async (favouritesList, favType, operationType) => {
  const url = `${qtpApi}/user_favourites_setting/${operationType}`;
  const paramData = { variable_type: `${favType}`, variable_id: favouritesList };
  const res = await fetchClient.post(url, paramData);
  return res;
};

export const getUserFavourites = async (type) => {
  const url = `${qtpApi}/cricket/user_favourites`;
  const config = {
    params: type ? { type } : {}
  };
  const res = await fetchClient.get(url, config);
  const { data } = res;
  return data;
};

export const logError = async (payload) => {
  const url = `${qtpApi}/slack_error`;
  const res = await fetchClient.post(url, payload);
  const { data } = res;
  return data;
};
