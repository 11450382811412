const { useEffect } = require('react');

const usePageTitle = (title) => {
  useEffect(() => {
    if (title) {
      document.title = title || 'Video Scorecard';
    }
    return () => (document.title = 'Video Scorecard');
  }, [title]);
};

export default usePageTitle;
