import React from 'react';
import { RecoilRoot } from 'recoil';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router, ReactLocation, Outlet } from '@tanstack/react-location';
import { ReactLocationDevtools } from '@tanstack/react-location-devtools';
import ReactGA from 'react-ga4';

import AppErrorBoundary from '_shared/errors/AppErrorBoundary';
import { authenticatedRoutes } from './_shared/routes/routes';
import theme from './_shared/theme/index';

import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/400.css';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING;
if (GA_TRACKING_ID) {
  ReactGA.initialize(GA_TRACKING_ID);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});
const location = new ReactLocation();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppErrorBoundary>
        <ChakraProvider theme={theme}>
          <RecoilRoot>
            <Router location={location} routes={authenticatedRoutes}>
              <Outlet />
              {process.env.REACT_APP_ENV === 'dev' ? (
                <ReactLocationDevtools initialIsOpen={false} position="bottom-right" />
              ) : null}
            </Router>
          </RecoilRoot>
        </ChakraProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </AppErrorBoundary>
    </QueryClientProvider>
  );
}

export default App;
