import { Box, Flex } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import ScoreCardItemButton from './ScorecardItemButton';
import VideoPlayIcon from './VideoPlayIcon';

const PlayerCard = ({
  name,
  player_id,
  secondaryText,
  runs,
  balls_faced,
  wickets,
  conceded,
  isInactive,
  videoAvailable
}) => {
  const navigate = useNavigate();
  const setPlayerSelected = (value) => {
    navigate({ search: (old) => ({ ...old, playerId: value }) });
  };
  if (isInactive) {
    return (
      <Flex
        w="100%"
        alignItems="center"
        py="10px"
        px="4"
        gap="4"
        borderBottom="1px"
        borderColor="black.8"
        fontSize="xs"
        h="57px"
        textAlign="left"
      >
        <Flex direction="column" flexGrow="1">
          <Box color="black.32" fontWeight="700" fontSize="sm">
            {name}
          </Box>
          <Box color="black.60" fontWeight="400">
            {secondaryText}
          </Box>
        </Flex>
      </Flex>
    );
  }
  return (
    <ScoreCardItemButton handleClick={() => setPlayerSelected(player_id)}>
      <Flex direction="column" flexGrow="1">
        <Box color="black.100" fontWeight="700" fontSize="sm">
          {name}
        </Box>
        <Box color="black.60" fontWeight="400">
          {secondaryText}
        </Box>
      </Flex>
      {balls_faced && balls_faced !== 0 ? (
        <Flex gap="1">
          <Box color="black.100" fontWeight="700">
            {runs}
          </Box>
          <Box color="black.60" fontWeight="400">
            ({balls_faced})
          </Box>
        </Flex>
      ) : null}
      {wickets && conceded ? (
        <Box color="black.100" fontWeight="700">
          {wickets}/{conceded}
        </Box>
      ) : null}
      {videoAvailable ? <VideoPlayIcon /> : null}
    </ScoreCardItemButton>
  );
};

export default PlayerCard;
