import { Box } from '@chakra-ui/react';
import getPlayerData from './utils/getPlayerData';
import PlayerCard from './PlayerCard';
import ScoreCardItemButton from './ScorecardItemButton';
import VideoPlayIcon from './VideoPlayIcon';
import { useNavigate, useSearch } from '@tanstack/react-location';

const VideoScorecard = ({ inningsData, teamsData }) => {
  const navigate = useNavigate();
  const search = useSearch();
  const displayInnings = () => {
    if (search.type === 'batting') {
      return inningsData.batting?.map((batterInns) => {
        const { balls_faced, dismissal_str, runs, batted, player_id } = batterInns;
        const batter = getPlayerData(teamsData, player_id, inningsData.batting_team_id);
        const { name } = batter;
        return (
          <PlayerCard
            key={player_id}
            name={name}
            player_id={player_id}
            secondaryText={dismissal_str}
            runs={runs}
            balls_faced={balls_faced}
            isInactive={batted !== 'yes'}
            videoAvailable={balls_faced !== '0'}
          />
        );
      });
    }
    if (search.type === 'bowling') {
      return inningsData.bowling?.map((bowlerInns) => {
        const { player_id, overs, maidens, bowling_economy_rate, conceded, wickets } = bowlerInns;
        const bowler = getPlayerData(teamsData, player_id, inningsData.bowling_team_id);
        const { name } = bowler;
        return (
          <PlayerCard
            key={player_id}
            name={name}
            player_id={player_id}
            secondaryText={`Overs ${overs}, Maidens ${maidens}, Economy ${bowling_economy_rate}`}
            videoAvailable
            conceded={conceded}
            wickets={wickets}
          />
        );
      });
    }
  };

  const setGroupSelected = (value) => {
    navigate({ search: (old) => ({ ...old, group: value }) });
  };

  const displayInningsGroups = () => {
    const { boundaries, wickets } = inningsData;
    return (
      <Box borderTop="3px solid" borderColor="black.8" mb={{ base: '85px', md: 0 }}>
        <ScoreCardItemButton handleClick={() => setGroupSelected('wickets')}>
          <Box fontWeight="700" flexGrow="1" fontSize="sm">
            All Wickets
          </Box>
          <Box color="black.60" fontWeight="400">
            ({wickets})
          </Box>
          {wickets !== '0' ? <VideoPlayIcon /> : null}
        </ScoreCardItemButton>
        <ScoreCardItemButton handleClick={() => setGroupSelected('boundaries')}>
          <Box fontWeight="700" flexGrow="1" fontSize="sm">
            All Boundaries
          </Box>
          <Box color="black.60" fontWeight="400">
            ({boundaries})
          </Box>
          {boundaries !== '0' ? <VideoPlayIcon /> : null}
        </ScoreCardItemButton>
      </Box>
    );
  };
  return (
    <>
      {displayInnings()}
      {displayInningsGroups()}
    </>
  );
};

export default VideoScorecard;
