import { Box, Flex, IconButton } from '@chakra-ui/react';
import { MdChevronLeft } from 'react-icons/md';

const NavHeader = ({ handleBack, buttonVariant, children }) => {
  return (
    <Flex p="10px" alignItems="center" justify="space-between" h="72px">
      <Box w="40px">
        <IconButton
          size="sm"
          icon={<MdChevronLeft size={24} />}
          variant={buttonVariant}
          onClick={handleBack}
        />
      </Box>
      <Box flexGrow={1}>{children}</Box>
      <Box w="40px" />
    </Flex>
  );
};

export default NavHeader;
