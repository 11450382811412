import { Box, Center, Flex, Image } from '@chakra-ui/react';
import { Outlet } from '@tanstack/react-location';
import useUserDetails from '_shared/dataFetching/hooks/useUserDetails';
import MenuDrawer from './MenuDrawer/MenuDrawer';

const MainContainer = () => {
  const { data, isLoading } = useUserDetails({ retry: false });

  return (
    <>
      <Box
        position="fixed"
        top="0"
        left="0"
        backgroundSize="cover"
        backgroundPosition="center"
        bgImage="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/UK_kiaoval_sunset.jpg"
        w="100%"
        h="100vh"
        zIndex="-2"
      />
      <Box
        position="fixed"
        top="0"
        left="0"
        right="0"
        marginLeft="auto"
        marginRight="auto"
        h="100vh"
        w="100%"
        maxW="600px"
        zIndex="-1"
        bg="white.100"
      />
      <Flex
        direction="column"
        bg="white.100"
        w="100%"
        maxW="600px"
        margin="auto"
        minH="0"
        h={{ md: '100vh' }}
      >
        {isLoading ? (
          <Center h="100vh" w="100%">
            <Image src="https://cv-qtp-images.s3.eu-west-2.amazonaws.com/cricviz-logo.png" />
          </Center>
        ) : null}
        {data ? (
          <>
            <MenuDrawer />
            <Outlet />
          </>
        ) : null}
      </Flex>
    </>
  );
};

export default MainContainer;
