import { Flex, Spinner } from '@chakra-ui/react';
import { getCompetitionMatches } from '_shared/dataFetching/apiService';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { useEffect, useState } from 'react';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import CompetitionList from '_shared/components/CompetitionList';

const CompList = () => {
  const {
    params: { objectId: compId }
  } = useMatch();
  const { isLoading, data } = useQueryFetch(
    ['videoListings'],
    () => getCompetitionMatches(compId),
    {
      staleTime: 30 * 1000
    }
  );
  const [tabVal, setTabVal] = useState('live_complete');

  const search = useSearch();
  const navigate = useNavigate();
  useEffect(() => {
    console.log('set comp params');
    if (search.type || search.innings) {
      navigate({
        search: (old) => ({ ...old, type: undefined, innings: undefined }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [search.type, search.innings, navigate]);

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center" h="100vh">
        <Spinner />
      </Flex>
    );
  }
  if (data) {
    return <CompetitionList data={data} tabVal={tabVal} setTabVal={setTabVal} />;
  }
  return null;
};

export default CompList;
