import { Flex } from '@chakra-ui/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import InfoItem from '_shared/components/DesignSystem/InfoItem';
import NavHeader from '../NavHeader/NavHeader';
import HeaderText from '../NavHeader/HeaderText';

const BatterInfoItems = ({ headerData }) => {
  const { batting_hand, runs, balls_faced, dismissal_str } = headerData;
  const battingHand = batting_hand === 'right' ? 'RHB' : batting_hand === 'left' ? 'LHB' : '';
  return (
    <>
      <Flex px="10px" pb="10px" gap="2">
        <InfoItem header="Runs" detail={runs} fontSize="xs" flex="1" />
        <InfoItem header="Balls" detail={balls_faced} fontSize="xs" flex="1" />
        <InfoItem header="Batting Hand" detail={battingHand} fontSize="xs" flex="1" />
      </Flex>
      <Flex px="10px" pb="10px" gap="2">
        <InfoItem header="Dismissal" detail={dismissal_str} fontSize="xs" flex="3" />
      </Flex>
    </>
  );
};

const BowlerInfoItems = ({ headerData }) => {
  const { overs, conceded, wickets, bowling_desc, maidens, bowling_economy_rate } = headerData;
  return (
    <>
      <Flex px="10px" pb="10px" gap="2">
        <InfoItem header="Overs" detail={overs} fontSize="xs" flex="1" />
        <InfoItem header="Runs Conceded" detail={conceded} fontSize="xs" flex="1" />
        <InfoItem header="Wickets" detail={wickets} fontSize="xs" flex="1" />
      </Flex>
      <Flex px="10px" pb="10px" gap="2">
        <InfoItem header="Economy" detail={bowling_economy_rate} fontSize="xs" flex="1" />
        <InfoItem header="Maidens" detail={maidens} fontSize="xs" flex="1" />
        <InfoItem header="Bowling Type" detail={bowling_desc} fontSize="xs" flex="1" />
      </Flex>
    </>
  );
};

const PlayerViewHeader = ({ headerData, matchName }) => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate({ search: (old) => ({ ...old, playerId: undefined }) });
  };
  const search = useSearch();
  return (
    <Flex bg="primary.shade.70" direction="column">
      <NavHeader handleBack={handleBack} buttonVariant="ghostDark">
        <HeaderText primaryText={headerData.name} secondaryText={matchName} />
      </NavHeader>
      {search.type === 'batting' ? <BatterInfoItems headerData={headerData} /> : null}
      {search.type === 'bowling' ? <BowlerInfoItems headerData={headerData} /> : null}
    </Flex>
  );
};

export default PlayerViewHeader;
