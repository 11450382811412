import { Flex } from '@chakra-ui/react';
import InfoItem from '_shared/components/DesignSystem/InfoItem';
import getExtras from '_shared/utils/getExtras';
import getInningsTotal from '_shared/utils/getInningsTotal';

const VideoScorecardFooter = ({ inningsData }) => {
  const { breakdown, total } = getExtras(inningsData);
  const inningsTotal = getInningsTotal(inningsData);
  return (
    <Flex bg="primary.shade.70" p="2" gap="2">
      <InfoItem header={`Extras - ${total}`} detail={breakdown} fontSize="xs" />
      <InfoItem header="Total" detail={inningsTotal} fontSize="xs" />
    </Flex>
  );
};

export default VideoScorecardFooter;
