import { Box } from '@chakra-ui/react';

const InfoItem = ({ header, detail, fontSize = 'md', flex = '1' }) => {
  return (
    <Box h="20x" bg="white.10" flex={flex} borderRadius="4" p="4">
      <Box fontSize="xs" color="white.56" fontWeight="700" letterSpacing="0px">
        {header}
      </Box>
      <Box fontSize={fontSize} color="white.100" fontWeight="700" letterSpacing="0px">
        {detail}
      </Box>
    </Box>
  );
};

export default InfoItem;
