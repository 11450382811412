import { Flex } from '@chakra-ui/react';

const ScoreCardItemButton = ({ handleClick, children }) => {
  return (
    <Flex
      as="button"
      w="100%"
      alignItems="center"
      py="10px"
      px="4"
      gap="4"
      borderBottom="1px"
      borderColor="black.8"
      fontSize="xs"
      h="57px"
      textAlign="left"
      onClick={handleClick}
      _hover={{
        '& .video-play-icon': {
          bg: 'positive.shade.10'
        },
        bg: 'black.3'
      }}
      _active={{
        '& .video-play-icon': {
          bg: 'positive.shade.20'
        }
      }}
    >
      {children}
    </Flex>
  );
};

export default ScoreCardItemButton;
