import { Box } from '@chakra-ui/react';

const LayoutContainer = ({ header, content, footer }) => {
  return (
    <>
      <Box zIndex="2" position="sticky" top="0">
        {header}
      </Box>
      <Box flexGrow="1" overflowY="auto">
        {content}
      </Box>
      <Box zIndex="2" position={{ base: 'fixed', md: 'sticky' }} bottom="0" w="100%" maxW="600px">
        {footer}
      </Box>
    </>
  );
};

export default LayoutContainer;
