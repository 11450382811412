export const greyCardHover = {
  rounded: 'md',
  bg: 'black.3',
  border: '1px',
  borderColor: 'transparent'
};

export const whiteCardHover = {
  rounded: 'md',
  bg: 'white.100',
  border: '1px',
  borderColor: 'black.8',
  _hover: { boxShadow: 'small', cursor: 'pointer', borderColor: 'black.24' },
  overflow: 'hidden'
};

export const selectedCardHover = {
  rounded: 'md',
  bg: 'white.100',
  border: '2px',
  borderColor: 'primary.main',
  _hover: { boxShadow: 'small' },
  overflow: 'hidden'
};
