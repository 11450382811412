import { Button } from '@chakra-ui/react';
import { Link as LocationLink } from '@tanstack/react-location';
const RouterButton = (props) => {
  return (
    <Button as={LocationLink} {...props}>
      {props.children}
    </Button>
  );
};
export default RouterButton;
