import { Box, List, ListItem, Flex, Icon } from '@chakra-ui/react';
import { MdPlayArrow } from 'react-icons/md';

const VideoList = ({ data, handleItemClick, selectedVideoIndex }) => {
  return (
    <List>
      {data
        ? data.map((el, idx) => {
            const { commentary, overs_unique } = el;
            return (
              <ListItem
                as="button"
                w="100%"
                flex="1"
                textAlign="left"
                display="flex"
                alignItems="center"
                gap="2"
                borderTop={idx !== 0 ? '1px' : '0px'}
                px="3"
                py="2"
                borderColor="black.8"
                bg={selectedVideoIndex === idx && 'black.8'}
                _hover={{
                  '& .video-play-icon': {
                    bg: 'positive.shade.10'
                  },
                  bg: 'black.3'
                }}
                _active={{
                  '& .video-play-icon': {
                    bg: 'positive.shade.20'
                  }
                }}
                onClick={() => handleItemClick(idx)}
              >
                <Flex
                  fontSize="xs"
                  h="6"
                  alignItems="center"
                  bg="positive.main"
                  color="white.100"
                  rounded="md"
                  pr="2"
                  pl="1"
                  className="video-play-icon"
                  minW="60px"
                  justify="center"
                  alignSelf="start"
                  mt="1"
                >
                  <Icon boxSize="16px" aria-label="show-videos" as={MdPlayArrow} />
                  {overs_unique}
                </Flex>
                <Box fontSize="sm">{commentary}</Box>
              </ListItem>
            );
          })
        : null}
    </List>
  );
};

export default VideoList;
