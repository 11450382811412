import { createIcon } from '@chakra-ui/icons';

const Questions = createIcon({
  displayName: 'Questions',
  viewBox: '0 0 24 24',
  path: [
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      fill="currentcolor"
    />,
    <path
      d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z"
      fill="currentcolor"
    />,
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99998 10.007C9.99999 10.0023 10 10.0007 9.99998 10.007ZM13.0049 13.6678C13.4381 13.3466 13.9683 13.1339 14.4472 12.8944C16.0769 12.0796 16.2805 10.1793 15.8237 8.80877C15.3418 7.36297 14.016 6 12 6C11.075 6 10.3148 6.23412 9.71093 6.6367C9.11332 7.03511 8.72739 7.55916 8.48057 8.05279C8.17713 8.65967 8.02832 9.32521 8 10C8 10.5523 8.44772 11 9 11C9.54996 11 9.99622 10.5561 9.99998 10.007C10.0099 9.64471 10.1081 9.26979 10.2694 8.94721C10.6078 8.27043 11.2659 8 12 8C12.984 8 13.6582 8.63703 13.9263 9.44123C14.2195 10.3207 13.9231 10.9204 13.5528 11.1056C13.1525 11.3057 12.7495 11.5022 12.3581 11.7196C12.0635 11.8833 11.7721 12.0637 11.5429 12.2929C10.9542 12.8816 11 13.5811 11 14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14C13 13.8197 13.0017 13.7236 13.0049 13.6678Z"
      fill="currentcolor"
    />
  ]
});

export default Questions;
