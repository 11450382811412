import { useSearch } from '@tanstack/react-location';
import useInningsData from '../utils/getInningsData';
import VideoScorecard from './VideoScorecard';
import VideoScorecardFooter from './VideoScorecardFooter';
import VideoScorecardHeader from './VideoScorecardHeader';
import LayoutContainer from '_shared/components/LayoutComponents/LayoutContainer';

const ScorecardView = ({ data }) => {
  const search = useSearch();
  const inningsData = useInningsData(data, search.innings);
  return (
    <LayoutContainer
      header={
        data.match && data.innings ? (
          <VideoScorecardHeader matchData={data.match} inningsData={data.innings} />
        ) : null
      }
      content={
        inningsData ? <VideoScorecard inningsData={inningsData} teamsData={data.team} /> : null
      }
      footer={inningsData ? <VideoScorecardFooter inningsData={inningsData} /> : null}
    />
  );
};

export default ScorecardView;
