import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { useEffect, useState } from 'react';
import { getScorecardVideos } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import capitalise from '_shared/utils/capitalise';
import Error from '../components/Error';
import Loading from '../components/Loading';
import VideoDisplay from '../VideoViewer/VideoDisplay';

const InningsGroup = ({ inningsData }) => {
  const {
    params: { objectId: matchId }
  } = useMatch();
  const navigate = useNavigate();
  const search = useSearch();
  const [fetchEnabled, setFetchEnabled] = useState(false);
  const groupSelected = search.group;
  const videoSelected = search.video;

  const fetchParams = { innings: inningsData.innings_number };
  if (groupSelected === 'wickets') {
    fetchParams.wickets = '1';
  }
  if (groupSelected === 'boundaries') {
    fetchParams.boundaries = '1';
  }
  const { isLoading, error, data } = useQueryFetch(
    ['scorecardVideos', matchId, fetchParams],
    () => getScorecardVideos(matchId, fetchParams),
    {
      enabled: fetchEnabled
    }
  );

  useEffect(() => {
    if (['wickets', 'boundaries'].includes(groupSelected)) {
      setFetchEnabled(true);
    } else {
      navigate({
        search: (old) => ({ ...old, group: undefined, video: undefined }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [groupSelected, navigate]);

  useEffect(() => {
    if (data && !data[videoSelected]) {
      navigate({
        search: (old) => ({ ...old, video: 0 }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [data, navigate, groupSelected, videoSelected]);

  const setGroupSelected = (value) => {
    navigate({ search: (old) => ({ ...old, group: value }) });
  };

  const setVideoSelected = (value) => {
    navigate({ search: (old) => ({ ...old, video: value }) });
  };

  const handleCloseVideoView = () => {
    setVideoSelected(undefined);
    setGroupSelected(undefined);
  };

  if (isLoading) return <Loading flexGrow="1" />;
  if (error) return <Error flexGrow="1" error={error} />;

  if (data) {
    return (
      <VideoDisplay
        onCloseVideo={handleCloseVideoView}
        groupData={data}
        videoSelected={videoSelected}
        setVideoSelected={setVideoSelected}
        primaryLabel={capitalise(groupSelected)}
        secondaryLabel={inningsData.label}
      />
    );
  }
  return null;
};

export default InningsGroup;
