const colors = {
  primary: {
    main: '#0C5DFF',
    tint: {
      10: '#246DFF',
      20: '#3D7DFF',
      30: '#558EFF',
      40: '#6D9EFF',
      50: '#86AEFF',
      60: '#9EBEFF',
      70: '#B6CEFF',
      80: '#CEDFFF',
      90: '#E7EFFF'
    },
    shade: {
      10: '#0B54E6',
      20: '#0A4ACC',
      30: '#0841B3',
      40: '#073899',
      50: '#062F80',
      60: '#052566',
      70: '#041C4C',
      80: '#021333',
      90: '#010919'
    }
  },
  positive: {
    main: '#40CD78',
    tint: {
      10: '#53D286',
      20: '#66D793',
      30: '#79DCA1',
      40: '#8CE1AE',
      50: '#A0E6BC',
      60: '#B3EBC9',
      70: '#C6F0D7',
      80: '#D9F5E4',
      90: '#ECFAF2'
    },
    shade: {
      10: '#3AB96C',
      20: '#33A460',
      30: '#2D9054',
      40: '#267B48',
      50: '#20673C',
      60: '#1A5230',
      70: '#133D24',
      80: '#0D2918',
      90: '#06140C'
    }
  },
  winviz: {
    0: '#C6F0D7',
    1: '#B3EBC9',
    2: '#A0E6BC',
    3: '#8CE1AE',
    4: '#79DCA1',
    5: '#66D793',
    6: '#53D286',
    7: '#40CD78',
    8: '#3AB96C',
    9: '#33A460',
    10: '#33A460'
  },
  captaincy: {
    red: '#FF0000',
    green: '#008000',
    amber: '#FFBF00'
  },
  negative: {
    main: '#ED4444',
    tint: {
      90: '#FDECEC'
    },
    shade: {
      10: '#D53D3D',
      30: '#A63030'
    }
  },
  warning: {
    main: '#FFAF21',
    shade: {
      10: '#E69E1E'
    },
    tint: {
      90: '#FFF7E9'
    }
  },
  black: {
    100: '#040921',
    76: '#404456',
    60: '#686B7A',
    32: '#AFB0B8',
    24: '#C3C4CA',
    8: '#EBEBED',
    4: '#F5F5F6',
    3: '#F8F8F8'
  },
  white: {
    100: '#ffffff',
    56: 'rgba(255, 255, 255, 0.56)',
    32: 'rgba(255, 255, 255, 0.32)',
    20: 'rgba(255, 255, 255, 0.2)',
    10: 'rgba(255, 255, 255, 0.1)'
  },
  table: {
    100: '#F8F8F8'
  },
  favourite: '#FFC764',
  background: '#F5F5F5',
  header: '#f8f8f8',
  ballScore: {
    nr: {
      bg: '#0000000f',
      color: '#AFB0B8',
      border: 'transparent'
    },
    r: {
      bg: '#FFFFFFCC',
      color: '#686B7A',
      border: '#C3C4CA'
    },
    4: {
      bg: '#0ac6ffcc',
      color: 'white',
      border: '#0CC5FF'
    },
    5: {
      bg: '#790CFF',
      color: 'white',
      border: '#790CFF'
    },
    6: {
      bg: '#CE0CFF',
      color: 'white',
      border: '#CE0CFF'
    },
    w: {
      bg: '#ffae21cc',
      color: 'white',
      border: '#FFAF21'
    }
  },
  grey: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#E4E7EC',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828'
  },
  cricket: {
    50: '#e69997',
    header: '#e1848285',
    100: '#e18482',
    200: '#dc706d',
    300: '#d75b58',
    400: '#d24743',
    500: '#ce322e',
    600: '#b92d29',
    700: '#a42825',
    800: '#902320',
    900: '#7b1e1c',
    LH: 'orange',
    RH: 'orchid'
  },
  cricketSecondary: {
    main: '#e9e1cd',
    dark: '#cec5b4'
  }
};

export default colors;
