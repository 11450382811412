import { Box, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getScorecardVideos } from '_shared/dataFetching/apiService';
import useQueryFetch from '_shared/dataFetching/hooks/useQueryFetch';
import VideoDisplay from '../VideoViewer/VideoDisplay';
import getBBBGroups from './utils/getBBBGroups';
import PlayerViewAccordian from './PlayerViewAccordian';
import PlayerViewHeader from './PlayerViewHeader';
import getHeaderInfo from './utils/getHeaderInfo';
import groupDataByRunsScored from './utils/groupDataByRunsScored';
import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import Loading from '../components/Loading';
import Error from '../components/Error';
import LayoutContainer from '_shared/components/LayoutComponents/LayoutContainer';

const PlayerView = ({ inningsData, teamsData, matchName }) => {
  const {
    params: { objectId: matchId }
  } = useMatch();
  const search = useSearch();
  const navigate = useNavigate();
  const playerSelected = search.playerId?.toString();
  const groupSelected = search.group;
  const videoSelected = search.video;
  const [fetchEnabled, setFetchEnabled] = useState(false);
  const [accordianValue, setAccordianValue] = useState(0);

  const fetchParams = { innings: inningsData.innings_number };
  if (search.type === 'batting') {
    fetchParams.batter = playerSelected;
  }
  if (search.type === 'bowling') {
    fetchParams.bowler = playerSelected;
  }
  const { isLoading, error, data } = useQueryFetch(
    ['scorecardVideos', matchId, fetchParams],
    () => getScorecardVideos(matchId, fetchParams),
    {
      enabled: fetchEnabled,
      select: (d) => groupDataByRunsScored(d, fetchParams)
    }
  );

  useEffect(() => {
    if (inningsData[search.type].some((el) => el.player_id === search.playerId.toString())) {
      setFetchEnabled(true);
    } else {
      navigate({
        search: (old) => ({ ...old, playerId: undefined }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [inningsData, navigate, search.playerId, search.type]);

  useEffect(() => {
    if ((groupSelected || videoSelected) && data && !data[groupSelected]?.[videoSelected]) {
      navigate({
        search: (old) => ({ ...old, video: undefined, group: undefined }),
        replace: true,
        fromCurrent: true
      });
    }
  }, [data, navigate, groupSelected, videoSelected]);

  const setVideoSelected = (value) => {
    navigate({ search: (old) => ({ ...old, video: value }) });
  };

  const setGroupVideoSelected = (group, video) => {
    navigate({ search: (old) => ({ ...old, group, video }) });
  };

  const handleCloseVideoView = () => {
    setGroupVideoSelected(undefined, undefined);
  };

  const handlePlayAll = () => {
    setGroupVideoSelected('all', 0);
  };

  const BBBGroups = getBBBGroups(search.type);
  const headerData = getHeaderInfo(inningsData, teamsData, playerSelected, search.type);

  if (data?.[groupSelected]?.[videoSelected]) {
    const groupLabel = BBBGroups.find((el) => el.value === groupSelected)?.label || '';
    return (
      <VideoDisplay
        onCloseVideo={handleCloseVideoView}
        groupData={data[groupSelected]}
        videoSelected={videoSelected}
        setVideoSelected={setVideoSelected}
        primaryLabel={groupLabel}
        secondaryLabel={headerData.name}
      />
    );
  }

  const displayContent = () => {
    if (isLoading) return <Loading h="200px" />;
    if (error) return <Error h="200px" error={error} />;
    if (data) {
      return (
        <Box mb={{ base: '85px', md: 0 }}>
          <PlayerViewAccordian
            data={data}
            setGroupVideoSelected={setGroupVideoSelected}
            accordianValue={accordianValue}
            onAccordianChange={setAccordianValue}
            BBBGroups={BBBGroups}
          />
        </Box>
      );
    }
  };
  return (
    <LayoutContainer
      header={<PlayerViewHeader matchName={matchName} headerData={headerData} />}
      content={displayContent()}
      footer={
        data ? (
          <Box onClick={handlePlayAll} p="2" borderTop="1px" borderColor="black.8" bg="white.100">
            <Button variant="positive" w="100%">
              Play All
            </Button>
          </Box>
        ) : null
      }
    />
  );
};

export default PlayerView;
