const getColor = (value, type) => {
  if (value === 'wickets') {
    return `ballScore.w.${type}`;
  }
  if (value === 'sixes') {
    return `ballScore.6.${type}`;
  }
  if (value === 'fours') {
    return `ballScore.4.${type}`;
  }
  if (value === 'twosThrees' || value === 'singles') {
    return `ballScore.r.${type}`;
  }
  if (value === 'dots') {
    return `ballScore.nr.${type}`;
  }
  return null;
};

export default getColor;
