const { Box, Center } = require('@chakra-ui/react');

const Error = ({ error, ...boxProps }) => {
  return (
    <Box {...boxProps}>
      <Center h="100%">{error.message}</Center>
    </Box>
  );
};

export default Error;
