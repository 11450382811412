import { Icon } from '@chakra-ui/react';
import { MdPlayArrow } from 'react-icons/md';

const VideoPlayIcon = () => {
  return (
    <Icon
      boxSize="28px"
      rounded="md"
      aria-label="show-videos"
      bg="positive.main"
      color="white"
      as={MdPlayArrow}
      className="video-play-icon"
    />
  );
};

export default VideoPlayIcon;
