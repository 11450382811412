import { Navigate } from '@tanstack/react-location';
import MainContainer from '_shared/components/MainContainer';
import TestRoute from '_shared/components/TestRoute';
import LoginForm from 'authentication/components/LoginForm';
import ErrorRouteComponent from './ErrorRouteComponent';
import CompList from 'pages/comp/components/CompList';
import VideoScorecardPage from 'pages/videoScorecard/VideoScorecardPage';

const children = [
  { path: '/home/', element: <CompList /> },
  { path: '/comp/:objectId', element: <CompList /> },

  {
    path: '/match/:objectId',
    element: <VideoScorecardPage />
  }
];
if (process.env.NODE_ENV === 'development') {
  children.push({ path: 'test', element: <TestRoute /> });
}
if (process.env.REACT_APP_ENV === 'staging' || process.env.NODE_ENV === 'development') {
  children.push({ path: 'error', element: <ErrorRouteComponent /> });
}
children.push({ path: '/*', element: <Navigate to="home" /> });

export const authenticatedRoutes = [
  { path: '/login', element: <LoginForm /> },
  { path: '/', element: <Navigate to="home" /> },
  {
    key: 'auth-route',
    element: <MainContainer />,
    children
  }
];
