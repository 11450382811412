import { Box, Flex, Image, ListItem, Tooltip, Icon } from '@chakra-ui/react';
import { useNavigate } from '@tanstack/react-location';
import {
  MdAccessTime,
  MdOutlineDateRange,
  MdCheckCircleOutline,
  MdOutlineCancel
} from 'react-icons/md';

import {
  whiteCardHover,
  greyCardHover
} from '_shared/components/cssStylePatterns/cssStylePatterns';
import IconLabel from '_shared/components/IconLabel';
import { parseDateTimeLocal } from '_shared/utils/dateTimeFunctions';
import ViewsSinceDisplay from '../ViewsSinceDisplay';
import TeamScore from './TeamScore';
import { getInningsData } from './utils';
import FixtureStatusHeader from './FixtureStatusHeader';
import { RedBall, WhiteBall } from '_icons/svgs';

function getClassIcon(class_id) {
  if ([1, 4, 11, 14].includes(class_id)) {
    return RedBall;
  }
  return WhiteBall;
}

const MatchFixtureDateTime = ({
  dateTime,
  startTimeLocal,
  sameDay,
  endDay,
  dayNumber,
  matchTitle,
  component,
  hasVideo
}) => {
  const startTime = parseDateTimeLocal(dateTime, 'HH:mm');
  // const localTimeZone = getLocalTimeZone();
  const splitDate = String(new Date(dateTime)).split(' ');
  const splitEndDate = String(new Date(endDay)).split(' ');

  let matchDate;
  if (sameDay) {
    matchDate = `${splitDate[1]} ${splitDate[2]} ${splitDate[3]}`;
  } else {
    if (splitDate[2] === splitEndDate[2]) {
      matchDate = `${splitDate[1]}-${splitEndDate[1]} ${splitEndDate[2]} ${splitEndDate[3]}`;
    } else if (splitDate[3] === splitEndDate[3]) {
      matchDate = `${splitDate[1]} ${splitDate[2]} - ${splitEndDate[1]} ${splitEndDate[2]} ${splitEndDate[3]}`;
    } else {
      matchDate = `${splitDate[1]} ${splitDate[2]} ${splitDate[3]}  - ${splitEndDate[1]} ${splitEndDate[2]} ${splitEndDate[3]}`;
    }
  }
  return (
    <Tooltip
      label={`${startTimeLocal} Local Start Time`}
      isDisabled={!startTimeLocal}
      placement="bottom-start"
    >
      <Flex
        gap="3"
        alignItems="center"
        width="100%"
        color={'black.60'}
        h="40px"
        borderBottom={component === 'live' ? '1px' : null}
        borderBottomColor={component === 'live' ? 'black.8' : null}
      >
        <Flex gap={3} mt={component === 'live' ? -2 : -2}>
          {(dayNumber && !sameDay) || (matchTitle && matchTitle !== '') ? (
            <Flex gap="1">
              {dayNumber && !sameDay ? <Box fontSize="xs">Day {dayNumber}</Box> : null}
              {matchTitle && matchTitle !== '' && dayNumber && !sameDay ? (
                <Box fontSize="xs"> - </Box>
              ) : null}
              {matchTitle && matchTitle !== '' ? <Box fontSize="xs">{matchTitle}</Box> : null}
            </Flex>
          ) : null}

          <IconLabel icon={MdOutlineDateRange} label={matchDate} fontSize="xs" />
          <IconLabel icon={MdAccessTime} label={startTime} fontSize="xs" />
          <IconLabel
            icon={hasVideo ? MdCheckCircleOutline : MdOutlineCancel}
            label={hasVideo ? `Video available` : `Video unavailable`}
            fontSize="xs"
          />
        </Flex>
      </Flex>
    </Tooltip>
  );
};

const BottomLine = ({
  general_class_id,
  international_class_name,
  general_class_name,
  comp_name,
  ground_name
}) => {
  return (
    <Flex pt={2} pb={0} mt={'auto'} mb="auto" h={'40px'} borderTop="1px" borderColor="black.8">
      <Flex
        rounded="full"
        border="1px"
        borderColor="black.8"
        px="1"
        py="2px"
        fontSize="xs"
        color="black.60"
        alignItems="center"
        gap="1"
      >
        <Icon as={getClassIcon(general_class_id)} mr={1} />

        <Box fontSize="xs" mr={1}>
          {international_class_name ? international_class_name : general_class_name}
        </Box>
      </Flex>
      <Box fontSize="xs" color={'black.60'} ml={2} mt="auto" mb="auto">
        {comp_name || ground_name}
      </Box>
    </Flex>
  );
};

const ForthcomingMatch = ({ matchData }) => {
  const {
    start_datetime_utc,
    start_time_local,
    end_date,
    comp_name,
    ground_name,
    team1_name,
    team1_logo,
    team2_name,
    team2_logo,
    last_accessed,
    page_count,
    scheduled_overs,
    day_number,
    general_class_id,
    international_class_name,
    general_class_name,
    title
  } = matchData;
  return (
    <ListItem p="2" {...greyCardHover}>
      <Flex>
        <Flex direction="column" flexGrow="1" gap="1">
          <Flex direction="row" flexGrow="1" gap="1" borderBottom="1px" borderBottomColor="black.8">
            {start_datetime_utc ? (
              <MatchFixtureDateTime
                dateTime={start_datetime_utc}
                startTimeLocal={start_time_local}
                endDay={end_date}
                sameDay={scheduled_overs !== 0}
                dayNumber={day_number}
                matchTitle={title}
                component="forthcoming"
                hasVideo={false}
              />
            ) : null}
          </Flex>

          <Flex fontSize="md" fontWeight="700" gap="2" alignItems="center" h="60px">
            <Flex direction="row" gap={2} w="50%">
              {team1_logo ? (
                <Image
                  rounded="2px"
                  w="30px"
                  border="1px"
                  borderColor="black.8"
                  h="auto"
                  src={team1_logo}
                  boxSize="46"
                  objectFit="contain"
                />
              ) : null}
              <Box mt="auto" mb="auto" mr="auto">{`${team1_name || 'TBC'}`}</Box>
            </Flex>

            <Flex direction="row" gap={2} ml="auto" w="50%">
              <Box mt="auto" mb="auto" ml="auto">
                {`${team2_name || 'TBC'}`}
              </Box>
              {team2_logo ? (
                <Image
                  rounded="2px"
                  border="1px"
                  borderColor="black.8"
                  h="auto"
                  src={team2_logo}
                  boxSize="46"
                  objectFit="contain"
                />
              ) : null}
            </Flex>
          </Flex>

          <BottomLine
            general_class_id={general_class_id}
            international_class_name={international_class_name}
            general_class_name={general_class_name}
            comp_name={comp_name}
            ground_name={ground_name}
          />

          {last_accessed && page_count ? (
            <ViewsSinceDisplay item={{ last_accessed, page_count }} />
          ) : null}
        </Flex>
      </Flex>
    </ListItem>
  );
};

const LiveCompleteMatch = ({ matchData, handleClick }) => {
  const {
    team1_logo,
    team2_logo,
    team1_id,
    team2_id,
    team1_name,
    team2_name,
    team1_abbreviation,
    team2_abbreviation,
    innings,
    comp_name,
    ground_name,
    start_datetime_utc,
    start_time_local,
    scheduled_overs,
    end_date,
    day_number,
    general_class_id,
    international_class_name,
    general_class_name,
    title,
    has_video
  } = matchData;
  const inningsData = getInningsData({
    match: {
      team1_id,
      team2_id,
      team1_name,
      team2_name,
      team1_abbreviation,
      team2_abbreviation,
      team1_logo_url: team1_logo,
      team2_logo_url: team2_logo
    },
    innings
  });
  const batting_first_team_id = innings[0]?.team_id || team1_id;
  const batting_second_team_id = team1_id === batting_first_team_id ? team2_id : team1_id;
  if (!has_video) {
    delete whiteCardHover._hover;
  } else {
    whiteCardHover._hover = { boxShadow: 'small', cursor: 'pointer', borderColor: 'black.24' };
  }
  return (
    <ListItem {...whiteCardHover} onClick={has_video ? handleClick : null}>
      <FixtureStatusHeader matchData={matchData} favouriteAvailable />
      <Flex px="4" py="2" gap="3" direction="column">
        {start_datetime_utc ? (
          <MatchFixtureDateTime
            dateTime={start_datetime_utc}
            startTimeLocal={start_time_local}
            sameDay={scheduled_overs !== 0}
            endDay={end_date}
            dayNumber={day_number}
            matchTitle={title}
            component="live"
            hasVideo={has_video}
          />
        ) : null}
        {batting_first_team_id && batting_second_team_id ? (
          <Flex gap="3" flexWrap="wrap">
            <TeamScore inningsData={inningsData[batting_first_team_id]} />
            <TeamScore inningsData={inningsData[batting_second_team_id]} />
          </Flex>
        ) : null}
        <BottomLine
          general_class_id={general_class_id}
          international_class_name={international_class_name}
          general_class_name={general_class_name}
          comp_name={comp_name}
          ground_name={ground_name}
        />
      </Flex>
    </ListItem>
  );
};

const FixtureComponent = ({ matchData }) => {
  const navigate = useNavigate();
  const { id, match_status } = matchData;
  if (!match_status) {
    return null;
  }

  const handleClick = () => {
    navigate({ to: `/match/${id}` });
  };

  if (match_status === 'live' || match_status === 'complete') {
    return (
      <LiveCompleteMatch
        matchData={matchData}
        handleClick={matchData?.has_video ? handleClick : null}
      />
    );
  }

  if (match_status === 'forthcoming' || match_status === 'recent_view') {
    return <ForthcomingMatch matchData={matchData} />;
  }
  return null;
};

export default FixtureComponent;
