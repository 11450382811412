import { Box, List, Icon, Flex, Center } from '@chakra-ui/react';
import { MdInfoOutline } from 'react-icons/md';

import FixtureComponent from '_shared/components/FixtureComponent/FixtureComponent';
import ButtonSelector from '_shared/components/ButtonSelector';
import LayoutContainer from './LayoutComponents/LayoutContainer';

const errorMessage = (match_type) => {
  return (
    <Center h="100%" w="100%" minH="300px">
      <Flex direction="column" alignItems="center" gap="5" mb="14">
        <Icon as={MdInfoOutline} color="primary.tint.80" boxSize="48px" />
        <Box mb="2" color="black.60">
          There are no {match_type} matches
        </Box>
      </Flex>
    </Center>
  );
};

const CompetitionList = ({ data, tabVal, setTabVal }) => {
  const { matches, comp_info } = data;
  const { name } = comp_info;
  const tabs = [
    {
      value: 'live_complete',
      label: matches?.live.length > 0 ? 'Live/Complete' : 'Complete'
    },
    { value: 'forthcoming', label: 'Upcoming' }
  ];
  return (
    <LayoutContainer
      header={
        <Flex
          direction="column"
          alignItems="center"
          gap="3"
          p="5"
          borderBottom="1px"
          borderColor="black.8"
          bg="white.100"
        >
          <Box fontWeight="bold" fontSize="md" lineHeight="1">
            {name?.toUpperCase()}
          </Box>
          <ButtonSelector
            handleChange={setTabVal}
            value={tabVal}
            options={tabs}
            buttonMinWidth="140px"
          />
        </Flex>
      }
      content={
        <Box pb="2" pt="4" px="4" bg="black.3" h="100%">
          {tabVal === 'live_complete' ? (
            <List spacing="2">
              {matches?.live?.map((el, idx) => {
                return <FixtureComponent matchData={el} key={`${idx}-live`} />;
              })}
              {matches?.complete?.map((el, idx) => {
                return <FixtureComponent matchData={el} key={`${idx}-complete`} />;
              })}
              {matches?.live?.length + matches?.complete.length === 0
                ? errorMessage('live/complete')
                : null}
            </List>
          ) : null}
          {tabVal === 'forthcoming' ? (
            <List spacing="2">
              {matches?.forthcoming.length === 0
                ? errorMessage('upcoming')
                : matches?.forthcoming?.map((el, idx) => {
                    return <FixtureComponent matchData={el} key={`${idx}-forthcoming`} />;
                  })}
            </List>
          ) : null}
        </Box>
      }
    />
  );
};

export default CompetitionList;
