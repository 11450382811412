const groupDataByRunsScored = (data, fetchParams) => {
  const groups = {
    dots: [],
    singles: [],
    twosThrees: [],
    fours: [],
    sixes: [],
    wickets: [],
    all: []
  };
  if (data && Array.isArray(data)) {
    data.forEach((ballData) => {
      const { runs_off_bat, dismissal } = ballData;
      // add to all array
      groups.all.push(ballData);
      // check if batter is dismissed, ignore if non stiker run out
      if (dismissal !== null && dismissal !== 'nonstriker run out') {
        groups.wickets.push(ballData);
        return;
      }
      // check non stiker run out and add only if batter id doesn't match
      if (
        dismissal === 'nonstriker run out' &&
        fetchParams?.batter !== ballData?.batter_id?.toString()
      ) {
        groups.wickets.push(ballData);
      }

      // add to runs groups if batter id matches
      if (!fetchParams.batter || fetchParams.batter === ballData?.batter_id?.toString()) {
        switch (runs_off_bat) {
          case 0:
            groups.dots.push(ballData);
            break;
          case 1:
            groups.singles.push(ballData);
            break;
          case 2:
          case 3:
            groups.twosThrees.push(ballData);
            break;
          case 4:
            groups.fours.push(ballData);
            break;
          case 6:
            groups.sixes.push(ballData);
            break;
          default:
            break;
        }
      }
    });
  }
  return groups;
};

export default groupDataByRunsScored;
